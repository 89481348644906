<template>
  <div class="dashboard" ref="dashboard">

      <div class="header">
        <div class="exit">
          <el-button type="text" @click="exitSys">退出</el-button>
        </div>
        <div class="user-icon">
          <i class="el-icon-user-solid"></i>
        </div>
        <div class="user-name">
          {{ username }}
        </div>

      </div>
      <div class="middle" ref="middle">
        <div class="nav" ref="nav">
          <el-menu

              :default-active="activeIndex"
              class="el-menu-vertical-demo"

              background-color="#545c64"

              text-color="#fff"
              active-text-color="#ffd04b"
              style="height: 100%;width: 100%">

            <el-menu-item index="1" @click="navigateTo('/dashboard/dataCenter')">
               <i class="el-icon-s-data"></i>
              <span slot="title">数据中心</span>
            </el-menu-item>

            <el-menu-item index="5"  @click="navigateTo('/dashboard/user')">
              <i class="el-icon-s-custom"></i>
              <span slot="title">人员管理</span>
            </el-menu-item>

            <el-menu-item index="2" @click="navigateTo('/dashboard/goods')">
              <i class="el-icon-s-goods"></i>
              <span slot="title">产品管理</span>
            </el-menu-item>

            <el-menu-item index="3" @click="navigateTo('/dashboard/order')">
              <i class="el-icon-s-order"></i>
              <span slot="title">订单管理</span>
            </el-menu-item>

          </el-menu>

        </div>

        <div class="contain" ref="contain">
          <router-view></router-view>  <!-- 用于渲染子路由的组件 -->
        </div>

      </div>


  </div>





</template>


<style>

body, html, .dashboard, .middle, .contain, .nav {
  margin: 0;
  padding: 0;
}
.el-menu {
  //border-right: none !important;
  border-right: solid 1px #409EFF !important;
}

.dashboard{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fafafa;

}

.dashboard .header{

  height: 50px;
  background: #4B6EA8;
  width: 100%;
  min-height: 50px;
  margin-bottom: 23px;


  display: flex;
  align-items: center; /* 垂直居中 */
}

.exit {

  height: 40px;
  width: 40px;
  margin-left: 10px;
}

.user-icon{

  height: 40px;

  margin-left: auto;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

.user-name{
  font-size: 14px;
  margin-left: 7px;


  height: 40px;

  margin-right: 10px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

.dashboard .middle{
  border: 1px solid #b9c7d3;

  display: flex;
  flex:1;
}
.dashboard .middle .nav{
  width: 150px;
  height: 100%;
}


.dashboard .middle .contain{

  flex:1;
  background: #fafafa;

}

</style>

<script>
export default {
  data(){
    return{
      activeIndex: '', // 默认活动项
      username:'',
    }
  },
  // 可以在这里添加更多逻辑
  methods: {
    adjustMiddleWidth() {
      const dashboard = this.$refs.dashboard;
      const contain = this.$refs.contain;



      // 确保 middle 和 contain 存在，避免未定义错误
      if (dashboard && contain) {

        if (dashboard.scrollWidth> dashboard.clientWidth) {
          dashboard.style.width = dashboard.scrollWidth + "px";
          console.log("scrollWidth:", contain.scrollWidth, "clientWidth:", contain.clientWidth);
          console.log("middleScrollWidth:", dashboard.scrollWidth, "middleClientWidth:", dashboard.clientWidth);


        } else {
          dashboard.style.width = "100%";
          if (dashboard.scrollWidth> dashboard.clientWidth) {
            dashboard.style.width = dashboard.scrollWidth + "px";


          }
        }
      }
    },
    exitSys(){
      localStorage.removeItem('token');
      this.$router.push({ name: 'Login' });
      },


    getUserName(){


        this.$axios.get(process.env.VUE_APP_BASE_API+'/api/user/findUserName',)
            .then(response => {
              if(response.data.success === true){
                this.username = response.data.data.name;
              }

            })
            .catch(error => {
              console.error('Error:', error);
            });

    },

    navigateTo(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    updateActiveIndex(path) {
      if (path.startsWith('/dashboard/dataCenter')) {
        this.activeIndex = '1';
      } else if (path.startsWith('/dashboard/goods')) {
        this.activeIndex = '2';
      } else if (path.startsWith('/dashboard/order')) {
        this.activeIndex = '3';
      } else if (path.startsWith('/dashboard/report')) {
        this.activeIndex = '3';
      } else if (path.startsWith('/dashboard/user')) {
        this.activeIndex = '5';
      }
      else {
        this.activeIndex = '1'; // 默认活动项
      }
    },
  },
  mounted() {

    this.updateActiveIndex(this.$route.path);
    this.getUserName();

    // 将绑定后的方法存储在 resizeHandler 中
    this.resizeHandler = this.adjustMiddleWidth.bind(this);

    this.$nextTick(() => {
      this.adjustMiddleWidth();
      // 监听窗口大小变化
      window.addEventListener("resize", this.resizeHandler);
    });


  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  },
}
</script>
