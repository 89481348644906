<template>
  <div class="month">
    <v-chart :options="chartOptions" autoresize style="width: 100%; height: 400px; min-width: 300px;max-width: 500px"></v-chart>
  </div>
</template>

<script>
import Vue from 'vue';
import VChart from 'vue-echarts';

Vue.component('v-chart', VChart);

export default {
  name: 'BarChart',
  data() {
    return {
      chartOptions: {
        title: {
          text: '近7天生产/报废/返工统计'
        },
        tooltip: {},
        xAxis: {
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri','Sat','Sun']
        },
        yAxis: {},
        series: [
          {
            name: 'product',
            type: 'bar',
            data: [100, 200, 300, 200, 300,200,100]
          },
          {
          name: 'scrap',
          type: 'bar',
          data: [2, 6, 2, 5, 3,2,4]
        },
          {
            name: 'rework',
            type: 'bar',
            data: [5, 13, 9, 25, 14,12,22]
          }
        ]
      }
    };
  }
};
</script>

<style scoped>
/* 确保图表容器有固定高度 */
.month{
  width: 100%;
}

</style>
