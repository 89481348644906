<template>
  <div class="login">
    <div class="login-header">
      <h1 class="login-title">鲁洛生产管理系统</h1>
    </div>
    <div class="login-middle" :style="backgroundStyle">
      <div class="login-window">
        <div class="window-header"></div>
        <div class="window-middle" >
          <div class="window-contain">
            <form @submit.prevent="login">
              <div class="form-item">
                <input type="text" v-model="form.username" placeholder="用户名" required />
              </div>
              <div class="form-item">
                <input type="password" v-model="form.password" placeholder="密码" required />
              </div>
              <div class="form-item">
                <button type="submit">登录</button>
              </div>
            </form>
          </div>
        </div>
        <div class="window-foot"></div>
      </div>
    </div>
    <div class="login-foot"></div>
  </div>
</template>

<script>

export default {
  computed: {
    backgroundStyle() {
      return {
        background: `url(${require('@/images/login_bg.jpg')}) no-repeat center top`,
        backgroundSize: 'cover',
      };
    },
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    login() {
      // 处理登录逻辑
      // Base64 编码用户名和密码
      const encodedUsername = btoa(this.form.username);
      const encodedPassword = btoa(this.form.password);

      // 构建请求数据
      const postData = {
        authName: encodedUsername,
        authPw: encodedPassword,
      };

      // 发送 POST 请求
      this.$axios.post(process.env.VUE_APP_BASE_API+'/api/login', postData)
          .then(response => {
            if (response.data.success) {
              // 模拟登录成功后跳转到 /dashboard
              localStorage.setItem('token', response.data.data.token);
              this.$router.push({ path: '/dashboard' });
            } else {
              alert('用户名或密码错误');
            }
          })
          .catch(error => {
            console.error('登录请求失败:', error);
          });
    }
  }
}
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  background: #4B6EA8;
}
.login-header {
  height: 10%;
  width: 100%;
  background: #4B6EA8;
  box-sizing: border-box;
  display: flex;

  text-align: center;
}

.login-title {
  color: black;
  font-size: 3em;
  font-family: 'Raleway', sans-serif;
  font-weight: 300; /* 设置为细长字体 */
  margin: 0; /* 消除默认的 margin */


}
.login-middle {
  height: 75%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.login-window {
  height: 350px;
  width: 350px;
  background: #D4E6FC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}
.window-header {
  width: 100%;
  height: 50px;
  background: #D4E6FC;
}
.window-middle {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.window-contain {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.form-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 60%;
}
input {
  flex-grow: 1; /* 增加输入框的flex-grow值，使其更长 */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
input:focus {
  border-color: #007BFF; /* 蓝色边框 */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6); /* 渐变的蓝色阴影 */
  outline: none; /* 移除默认的聚焦样式 */
}
button {
  width: 100%;
  padding: 10px;
  background: #0556a6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  word-spacing: 1em;
}
button:hover {
  background-color: #45a049;
}
.window-foot {
  width: 100%;
  height: 50px;
  background: #D4E6FC;
}
.login-foot {
  height: 15%;
  width: 100%;
  background: #b9c7d3;
}
</style>
