<template>
  <div>
  <v-chart :options="chartOptions" autoresize style="width: 100%; height: 400px; min-width: 400px;max-width: 500px"></v-chart>
  </div>
</template>

<script>
import Vue from 'vue';
import VChart from 'vue-echarts';

Vue.component('v-chart', VChart);

export default {
  name: 'BarChart',
  data() {
    return {
      chartOptions: {
        title: {
          text: '2024年每月产量统计'
        },
        tooltip: {},
        xAxis: {
          data: ['一月', '二月', '三月', '四月', '五月', '六月','七月','八月','九月','十月','十一月','十二月']
        },
        yAxis: {},
        series: [{
          name: 'Sales',
          type: 'bar',
          data: [50, 200, 360, 100, 100, 200,50, 200, 360, 100, 100, 200]
        }]
      }
    };
  }
};
</script>

<style scoped>
/* 确保图表容器有固定高度 */


</style>
