<template>
  <div>
    <el-button type="primary" @click="openDialog">新增产品</el-button>


    <el-dialog title="新增产品" :visible.sync="dialogFormVisible" @close="closeAndClean" >
      <el-form :model="form" label-position="left"  >
        <el-form-item label="产品编码" :label-width="formLabelWidth" >
          <el-input v-model="form.goods" autocomplete="off" clearable  maxlength="20" @input="validateInput"></el-input>
        </el-form-item>
        <el-form-item label="款式" :label-width="formLabelWidth">
          <el-input v-model="form.style" autocomplete="off" clearable  maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="英文名" :label-width="formLabelWidth">
          <el-input v-model="form.englishName" autocomplete="off" clearable  maxlength="35"></el-input>
        </el-form-item>
        <el-form-item label="系列" :label-width="formLabelWidth">

          <el-select v-model="form.series" placeholder="请选择" @change="seriesChange">
            <el-option label="AT4" value="AT4"></el-option>
            <el-option label="ATR" value="ATR"></el-option>
            <el-option label="ATS" value="ATS"></el-option>
            <el-option label="AT5" value="AT5"></el-option>
            <el-option label="RG1" value="RG1"></el-option>
            <el-option label="RG2" value="RG2"></el-option>
            <el-option label="LITE" value="LITE"></el-option>

          </el-select>

        </el-form-item>
        <el-form-item label="尺码" :label-width="formLabelWidth" >

          <el-select v-model="form.size" placeholder="请选择"  v-if="form.series === 'AT5'">
            <el-option label="XS" value="XS"></el-option>
            <el-option label="S" value="S"></el-option>
            <el-option label="M" value="M"></el-option>
            <el-option label="L" value="L"></el-option>
            <el-option label="XL" value="XL"></el-option>
          </el-select>
          <el-select v-model="form.size" placeholder="请选择" v-else>
            <el-option label="XXS" value="XXS"></el-option>
            <el-option label="XS" value="XS"></el-option>
            <el-option label="S/M" value="S/M"></el-option>
            <el-option label="M/L" value="M/L"></el-option>
            <el-option label="L/XL" value="L/XL"></el-option>
            <el-option label="XL/XXL" value="XL/XXL"></el-option>
          </el-select>



        </el-form-item>
        <el-form-item label="数字尺码" :label-width="formLabelWidth">
          <el-input v-model="form.dSize" autocomplete="off" clearable  maxlength="15"></el-input>
        </el-form-item>
        <el-form-item label="内部尺码" :label-width="formLabelWidth">

          <el-select v-model="form.inSize" placeholder="请选择">
            <el-option label="S" value="1"></el-option>
            <el-option label="M" value="2"></el-option>
            <el-option label="L" value="3"></el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="外箱标签号" :label-width="formLabelWidth">
          <el-input v-model="form.outLabel" autocomplete="off" clearable  maxlength="15"></el-input>
        </el-form-item>
        <el-form-item label="内箱标签号" :label-width="formLabelWidth">
          <el-input v-model="form.inLabel" autocomplete="off" clearable  maxlength="15"></el-input>
        </el-form-item>

        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="form.remark" autocomplete="off" clearable  maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group v-model="form.kind" @input="radioChange">
            <el-radio :label="1">流程一：喷油——装配——装箱</el-radio>
            <el-radio :label="2">流程二：贴花——喷油——装配——装箱</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="喷油最小重量" :label-width="formLabelWidth">
          <el-input v-model="form.sprayWeightMin" autocomplete="off" @input="validateSprayMinInput"></el-input>
        </el-form-item>
        <el-form-item label="喷油最大重量" :label-width="formLabelWidth">
          <el-input v-model="form.sprayWeightMax" autocomplete="off" @input="validateSprayMaxInput" @blur="validateSprayMaxBlur"></el-input>
        </el-form-item>
        <el-form-item label="贴花最小重量" v-if="form.kind === 2" :label-width="formLabelWidth">
          <el-input v-model="form.appliqueWeightMin" autocomplete="off" @input="validateAppliqueMinInput"></el-input>
        </el-form-item>
        <el-form-item label="贴花最大重量" v-if="form.kind === 2" :label-width="formLabelWidth">
          <el-input v-model="form.appliqueWeightMax" autocomplete="off" @input="validateAppliqueMaxInput"></el-input>
        </el-form-item>
        <el-form-item label="装配最小重量" :label-width="formLabelWidth">
          <el-input v-model="form.assembleWeightMin" autocomplete="off" @input="validateAssembleMinInput"></el-input>
        </el-form-item>
        <el-form-item label="装配最大重量" :label-width="formLabelWidth">
          <el-input v-model="form.assembleWeightMax" autocomplete="off" @input="validateAssembleMaxInput"></el-input>
        </el-form-item>
        <el-form-item label="装箱最小重量" :label-width="formLabelWidth">
          <el-input v-model="form.encasementWeightMin" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="装箱最大重量" :label-width="formLabelWidth">
          <el-input v-model="form.encasementWeightMax" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeAndClean">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
    </div>



</template>


<style scoped>




.contain{
  width: 100%;
  height: 800px;

}
.contain .col{
  width: 100%;
  height:50px;
  background: coral;
  display: flex;
}

.contain .col .cell{
  height: 50px;
  flex: 1;
  background: aqua;
}


</style>

<script>

import axios from "axios";

export default {
  watch: {


  },
  data() {
    return {

      dialogFormVisible: false,
      form: {
        goods: '',
        style: '',
        size: '',
        englishName: '',
        dSize: '',
        inSize:'',
        outLabel: '',
        inLabel: '',
        series: '',
        remark:'',
        kind:'',
        sprayWeightMin:'',
        sprayWeightMax:'',
        appliqueWeightMin:'',
        appliqueWeightMax:'',
        assembleWeightMin:'',
        assembleWeightMax:'',
        encasementWeightMin:'',
        encasementWeightMax:'',
      },
      formLabelWidth: '120px',
      appliqueList:[],
    };
  },
  methods:{
    //装配重量
    validateAssembleMaxInput() {
      // 去除非数字字符
      let value = this.form.assembleWeightMax.replace(/[^\d]/g, '');

      // 将字符串转换为数字
      let numericValue = parseInt(value, 10);

      // 限制数字的范围：0 <= value <= 99999
      if (!isNaN(numericValue)) {
        if (numericValue > 99999) {

          numericValue = 99999;
        }
        if(this.form.assembleWeightMin !== ''){
          const min = parseInt(this.form.assembleWeightMin, 10);
          if(numericValue < min){
            numericValue = min;
          }
        }

        this.form.assembleWeightMax = numericValue.toString();
      } else {
        this.form.assembleWeightMax = '';
      }
    },

    validateAssembleMinInput() {
      // 去除非数字字符
      let value = this.form.assembleWeightMin.replace(/[^\d]/g, '');

      // 将字符串转换为数字
      let numericValue = parseInt(value, 10);

      // 限制数字的范围：0 <= value <= 99999
      if (!isNaN(numericValue)) {
        if (numericValue > 99999) {

          numericValue = 99999;
        }
        if(this.form.assembleWeightMax !== ''){
          const max = parseInt(this.form.assembleWeightMax, 10);
          if(numericValue > max){
            numericValue = max;
          }
        }
        this.form.assembleWeightMin = numericValue.toString();
      } else {
        this.form.assembleWeightMin = '';
      }
    },


    //贴花重量
    validateAppliqueMaxInput() {
      // 去除非数字字符
      let value = this.form.appliqueWeightMax.replace(/[^\d]/g, '');

      // 将字符串转换为数字
      let numericValue = parseInt(value, 10);

      // 限制数字的范围：0 <= value <= 99999
      if (!isNaN(numericValue)) {
        if (numericValue > 99999) {

          numericValue = 99999;
        }
        if(this.form.appliqueWeightMin !== ''){
          const min = parseInt(this.form.appliqueWeightMin, 10);
          if(numericValue < min){
            numericValue = min;
          }
        }

        this.form.appliqueWeightMax = numericValue.toString();
      } else {
        this.form.appliqueWeightMax = '';
      }
    },
    validateAppliqueMinInput() {
      // 去除非数字字符
      let value = this.form.appliqueWeightMin.replace(/[^\d]/g, '');

      // 将字符串转换为数字
      let numericValue = parseInt(value, 10);

      // 限制数字的范围：0 <= value <= 99999
      if (!isNaN(numericValue)) {
        if (numericValue > 99999) {

          numericValue = 99999;
        }
        if(this.form.appliqueWeightMax !== ''){
          const max = parseInt(this.form.appliqueWeightMax, 10);
          if(numericValue > max){
            numericValue = max;
          }
        }
        this.form.appliqueWeightMin = numericValue.toString();
      } else {
        this.form.appliqueWeightMin = '';
      }
    },


    //喷油
    validateSprayMaxBlur()  {
      var numericValue = parseInt(this.form.sprayWeightMax,10);


      if(this.form.sprayWeightMin !== ''){
        const min = parseInt(this.form.sprayWeightMin, 10);
        if(numericValue < min){
          numericValue = min;
        }
        this.form.sprayWeightMax = numericValue.toString();
      }
    },
    validateSprayMaxInput() {
      // 去除非数字字符
      let value = this.form.sprayWeightMax.replace(/[^\d]/g, '');

      // 将字符串转换为数字
      let numericValue = parseInt(value, 10);

      // 限制数字的范围：0 <= value <= 99999
      if (!isNaN(numericValue)) {
        if (numericValue > 99999) {

          numericValue = 99999;
        }


        this.form.sprayWeightMax = numericValue.toString();
      } else {
        this.form.sprayWeightMax = '';
      }
    },
    validateSprayMinInput() {
      // 去除非数字字符
      let value = this.form.sprayWeightMin.replace(/[^\d]/g, '');

      // 将字符串转换为数字
      let numericValue = parseInt(value, 10);

      // 限制数字的范围：0 <= value <= 99999
      if (!isNaN(numericValue)) {
        if (numericValue > 99999) {

          numericValue = 99999;
        }
        if(this.form.sprayWeightMax !== ''){
          const max = parseInt(this.form.sprayWeightMax, 10);
          if(numericValue > max){
            numericValue = max;
          }
        }
        this.form.sprayWeightMin = numericValue.toString();
      } else {
        this.form.sprayWeightMin = '';
      }
    },


    //产品编码输入限制
    validateInput() {
      // 允许的字符：数字、英文字母大小写和 -
      const regex = /[^a-zA-Z0-9-]/g;

      // 通过正则过滤掉不允许的字符
      this.form.goods = this.form.goods.replace(regex, '');
    },

    radioChange(){
      this.form.appliqueWeightMin = '',
      this.form.appliqueWeightMax = ''
    },

    seriesChange(){
      this.form.size = ''
    },


    openDialog(){
      this.dialogFormVisible = true;

      //发送请求获取applique列表
      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/goods/add/findApplique',)
          .then(response => {


            this.appliqueList = response.data.data.appliqueList;


          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    submitForm() {
      axios.post(process.env.VUE_APP_BASE_API+'/api/goods/add', this.form)
          .then(response => {
            if(response.data.success){
              this.form = {
                goods: '',
                style: '',
                size: '',
                englishName: '',
                dSize: '',
                inSize:'',
                outLabel: '',
                inLabel: '',
                series: '',
                remark:'',
                kind:'',
                sprayWeightMin:'',
                sprayWeightMax:'',
                appliqueWeightMin:'',
                appliqueWeightMax:'',
                assembleWeightMin:'',
                assembleWeightMax:'',
                encasementWeightMin:'',
                encasementWeightMax:'',
              };
              this.dialogFormVisible = false
              this.$emit("addGoods",true);
            }else{


              this.$emit("addGoods",false);
            }

          })
          .catch(error => {
            console.error('请求失败:', error);

            this.$emit("addGoods",false);
          });
    },
    closeAndClean() {
      this.form = {
        goods: '',
        style: '',
        size: '',
        englishName: '',
        dSize: '',
        inSize:'',
        outLabel: '',
        inLabel: '',
        series: '',
        remark:'',
        kind:'',
        sprayWeightMin:'',
        sprayWeightMax:'',
        appliqueWeightMin:'',
        appliqueWeightMax:'',
        assembleWeightMin:'',
        assembleWeightMax:'',
        encasementWeightMin:'',
        encasementWeightMax:'',
      };
      this.dialogFormVisible = false;
    }
  }
}

</script>