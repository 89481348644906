<template>
  <div>
    <v-chart :options="chartOptions" autoresize style="width: 100%; height: 400px; min-width: 300px;max-width: 500px"></v-chart>
  </div>
</template>

<script>
import Vue from 'vue';
import VChart from 'vue-echarts';

Vue.component('v-chart', VChart);

export default {
  name: 'BarChart',
  data() {
    return {
      chartOptions: {
        series: [
          {
            type: 'pie',
            data: [
              {
                value: 335,
                name: '欠数'
              },
              {
                value: 234,
                name: '库存'
              },
              {
                value: 1548,
                name: '出货'
              }
            ],
            radius:'50%'
          }
        ]
      }
    };
  }
};
</script>

<style scoped>
/* 确保图表容器有固定高度 */


</style>
