<template>
  <div>

    <el-button @click="openDetail" type="text" size="small">详情</el-button>


    <el-dialog :visible.sync="dialogFormVisible" title="订单总览" width="50%">


      <el-table
          :data="orderTable"
          border
          style="width: 100%;margin-top: 40px">
        <el-table-column
            prop="poId"
            label="订单号"
        >
        </el-table-column>
        <el-table-column
            width="80px"
            prop="quantity"
            label="数量"
        >
        </el-table-column>
        <el-table-column
            width="80px"
            prop="shipment"
            label="出货量">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="inventory"
            label="库存">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="lack"
            label="缺数">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="scrap"
            label="报废">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="realInput"
            label="实际投入">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="totalRework"
            label="返工次数">
        </el-table-column>
        <el-table-column
            prop="process"
            label="进度"
            :formatter="calculateCompletionRateOrder">
        </el-table-column>
      </el-table>


      <el-table
          :data="goodsTable"
          border
          style="width: 100%;margin-top: 40px">
        <el-table-column
            prop="goodsCode"
            label="产品编码"
        >
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsQuantity"
            label="数量"
        >
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsShipment"
            label="出货量">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsInventory"
            label="库存">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsLack"
            label="缺数">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsScrap"
            label="报废数">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="realInput"
            label="实际投入">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="totalRework"
            label="返工次数">
        </el-table-column>
        <el-table-column
            label="进度"
            :formatter="calculateCompletionRate">
        </el-table-column>
      </el-table>




      <span slot="footer" class="dialog-footer">

        <el-button type="primary" @click="submitOrder">进度报告</el-button>


    </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    row:{
      type:Object,
      required:true,
    }
  },
  data() {
    return {
      dialogFormVisible:false,
      order: {},
      orderTable:[],
      goods:{},
      goodsTable:[],
    };
  },
  methods: {

    calculateCompletionRateOrder(row) {
      if (row.quantity && row.inventory) {
        let completionRate = (row.inventory / row.quantity) * 100;
        return Math.round(completionRate) + '%'; // 取整
      }
      return '0%'; // 如果没有数量或库存，显示 0%
    },


    calculateCompletionRate(row) {
      if (row.goodsQuantity && row.goodsInventory) {
        let completionRate = (row.goodsInventory / row.goodsQuantity) * 100;
        return Math.round(completionRate) + '%'; // 取整
      }
      return '0%'; // 如果没有数量或库存，显示 0%
    },


    openDetail(){

      this.dialogFormVisible = true
      this.order = JSON.parse(JSON.stringify(this.row));
      this.orderTable = [this.order]
      this.goodsTable = this.order.goodsItems;
    },

    submitOrder() {
      var id = this.orderTable[0].poId
      this.$router.push(`/dashboard/report/${id}`);
    }
  }
};
</script>

<style scoped>
/* 子组件的样式 */

.row{
  width: 100%;
  display: flex;
}
.select{
  flex: 1;
  height: 50px;
}
.quantity{
  flex: 1;
  height: 50px;
}
.delete{
  flex: 1;
  height: 50px;
}
</style>
