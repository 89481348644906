<template>
  <div id="app">


    <router-view></router-view>

  </div>
</template>

<script>


export default {
  name: 'App',
  mounted() {
    this.checkLogin();
  },
  components: {

  },
  methods:{
    logout(){
      // 这里实现注销逻辑
      // 清除用户状态等

      this.$token = "";
      this.$router.push('/');
    },
    checkLogin(){
      if(!localStorage.getItem('token')){
        //为空或是空字符串
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }

      }else {

        this.$axios.get(process.env.VUE_APP_BASE_API + '/api/auth')
            .then(response => {
              if (response.data.success) {
                console.log("success");
              } else {
                if (this.$route.path !== '/') {
                  this.$router.push('/');
                }
              }

            })
            .catch(error => {
              console.error('Error:', error);
              if (this.$route.path !== '/') {
                this.$router.push('/');
              }
            });
      }
    },
  }
}
</script>

<style>



</style>
