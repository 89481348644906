<template>
  <div>


    <el-button type="text" size="small" @click="openDialog">编辑</el-button>
    <el-button  type="text" size="small" @click="deleteGoods">删除</el-button>


    <el-dialog title="编辑" :visible.sync="dialogFormVisible" @close="closeAndClean">
      <el-form :model="form" label-position="left">
        <el-form-item label="产品编码" :label-width="formLabelWidth">
          <el-input v-model="form.goods" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="款式" :label-width="formLabelWidth">
          <el-input v-model="form.style" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="英文名" :label-width="formLabelWidth">
          <el-input v-model="form.englishName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="系列" :label-width="formLabelWidth">

          <el-select v-model="form.series" placeholder="请选择" @change="seriesChange">
            <el-option label="AT4" value="AT4"></el-option>
            <el-option label="ATR" value="ATR"></el-option>
            <el-option label="ATS" value="ATS"></el-option>
            <el-option label="AT5" value="AT5"></el-option>
            <el-option label="RG1" value="RG1"></el-option>
            <el-option label="RG2" value="RG2"></el-option>
            <el-option label="LITE" value="LITE"></el-option>

          </el-select>

        </el-form-item>
        <el-form-item label="尺码" :label-width="formLabelWidth" >

          <el-select v-model="form.size" placeholder="请选择"  v-if="form.series === 'AT5'">
            <el-option label="XS" value="XS"></el-option>
            <el-option label="S" value="S"></el-option>
            <el-option label="M" value="M"></el-option>
            <el-option label="L" value="L"></el-option>
            <el-option label="XL" value="XL"></el-option>
          </el-select>
          <el-select v-model="form.size" placeholder="请选择" v-else>
            <el-option label="XXS" value="XXS"></el-option>
            <el-option label="XS" value="XS"></el-option>
            <el-option label="S/M" value="S/M"></el-option>
            <el-option label="M/L" value="M/L"></el-option>
            <el-option label="L/XL" value="L/XL"></el-option>
            <el-option label="XL/XXL" value="XL/XXL"></el-option>
          </el-select>



        </el-form-item>
        <el-form-item label="数字尺码" :label-width="formLabelWidth">
          <el-input v-model="form.dSize" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内部尺码" :label-width="formLabelWidth">

          <el-select v-model="form.inSize" placeholder="请选择">
            <el-option label="S" value="1"></el-option>
            <el-option label="M" value="2"></el-option>
            <el-option label="L" value="3"></el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="外箱标签号" :label-width="formLabelWidth">
          <el-input v-model="form.outLabel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内箱标签号" :label-width="formLabelWidth">
          <el-input v-model="form.inLabel" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="form.remark" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group v-model="form.kind" @input="radioChange">
            <el-radio :label="1">流程一：喷油——装配——装箱</el-radio>
            <el-radio :label="2">流程二：贴花——喷油——装配——装箱</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="喷油最小重量" :label-width="formLabelWidth">
          <el-input v-model="form.sprayWeightMin" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="喷油最大重量" :label-width="formLabelWidth">
          <el-input v-model="form.sprayWeightMax" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="贴花最小重量" v-if="form.kind === 2" :label-width="formLabelWidth">
          <el-input v-model="form.appliqueWeightMin" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="贴花最大重量" v-if="form.kind === 2" :label-width="formLabelWidth">
          <el-input v-model="form.appliqueWeightMax" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="装配最小重量" :label-width="formLabelWidth">
          <el-input v-model="form.assembleWeightMin" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="装配最大重量" :label-width="formLabelWidth">
          <el-input v-model="form.assembleWeightMax" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="装箱最小重量" :label-width="formLabelWidth">
          <el-input v-model="form.encasementWeightMin" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="装箱最大重量" :label-width="formLabelWidth">
          <el-input v-model="form.encasementWeightMax" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeAndClean">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>



</template>


<style scoped>




.contain{
  width: 100%;
  height: 800px;

}
.contain .col{
  width: 100%;
  height:50px;
  background: coral;
  display: flex;
}

.contain .col .cell{
  height: 50px;
  flex: 1;
  background: aqua;
}


</style>

<script>

import axios from "axios";

export default {
  props: {
    row:{
      type:Object,
      required:true,
    },
  },
  watch: {},
  data() {
    return {

      dialogFormVisible: false,
      form: {
        goods: '',
        style: '',
        size: '',
        englishName: '',
        dSize: '',
        inSize:'',
        outLabel: '',
        inLabel: '',
        series: '',
        remark:'',
        kind:'',
        sprayWeightMin:'',
        sprayWeightMax:'',
        appliqueWeightMin:'',
        appliqueWeightMax:'',
        assembleWeightMin:'',
        assembleWeightMax:'',
        encasementWeightMin:'',
        encasementWeightMax:'',
      },
      formLabelWidth: '120px',
      appliqueList:[],
    };
  },
  methods:{
    radioChange(){
      this.form.appliqueWeightMin = '',
          this.form.appliqueWeightMax = ''
    },

    seriesChange(){
      this.form.size = ''
    },

    deleteGoods(){

      this.$confirm('此操作将删除该产品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = {...this.row};
        axios.delete(process.env.VUE_APP_BASE_API+'/api/goods/delete/'+this.form.id)
            .then(response => {
              if(response.data.success){
                this.$emit("deleteGoods",true);
              }else{
                this.$emit("deleteGoods",false);
              }
            })
            .catch(error => {
              console.error('请求失败:', error);

              this.$emit("deleteGoods",false);
            });
      }).catch(() => {

      });


    },
    openDialog(){
      this.dialogFormVisible = true;
      this.form = {...this.row};
      this.form.inSize = String(this.row.inSize);


      //发送请求获取applique列表
      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/goods/add/findApplique',)
          .then(response => {
            this.appliqueList = response.data.data.appliqueList;

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    submitForm() {
      axios.put(process.env.VUE_APP_BASE_API+'/api/goods/edit', this.form)
          .then(response => {
            if(response.data.success){
              this.form = {
                goods: '',
                style: '',
                size: '',
                englishName: '',
                dSize: '',
                inSize:'',
                outLabel: '',
                inLabel: '',
                series: '',
                remark:'',
                kind:'',
                sprayWeightMin:'',
                sprayWeightMax:'',
                appliqueWeightMin:'',
                appliqueWeightMax:'',
                assembleWeightMin:'',
                assembleWeightMax:'',
                encasementWeightMin:'',
                encasementWeightMax:'',
              };
              this.dialogFormVisible = false
              this.$emit("editGoods",true);
            }else{


              this.$emit("editGoods",false);
            }

          })
          .catch(error => {
            console.error('请求失败:', error);

            this.$emit("editGoods",false);
          });
    },
    closeAndClean() {
      this.form = {
        goods: '',
        style: '',
        size: '',
        englishName: '',
        dSize: '',
        inSize:'',
        outLabel: '',
        inLabel: '',
        series: '',
        remark:'',
        kind:'',
        sprayWeightMin:'',
        sprayWeightMax:'',
        appliqueWeightMin:'',
        appliqueWeightMax:'',
        assembleWeightMin:'',
        assembleWeightMax:'',
        encasementWeightMin:'',
        encasementWeightMax:'',
      };
      this.dialogFormVisible = false;
    }
  }
}

</script>