<template>
  <div>
    <div class="common-header">
      <div class="three">
        <div class="window">
          <div class="window-header">
            <span class="name">产品日志</span>
          </div>
          <div class="window-contain">


                  <el-table
                      :data="tableData"
                      style="min-width: 200px; max-width: 500px"

                  >
                    <el-table-column
                        prop="date"
                        label="日期"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="姓名"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="事件">
                    </el-table-column>
                    <el-table-column
                        prop="object"
                        label="对象">
                    </el-table-column>
                  </el-table>


          </div>
        </div>
        <div class="window">
          <div class="window-header">
            <div class="name">
              订单日志
            </div> </div>
          <div class="window-contain">
            <el-table
                :data="tableData"
                style="min-width: 200px; max-width: 500px"

            >
              <el-table-column
                  prop="date"
                  label="日期"
              >
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="姓名"
              >
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="事件">
              </el-table-column>
              <el-table-column
                  prop="object"
                  label="对象">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="window">
          <div class="window-header">
            <div class="name">
              人员日志
            </div> </div>
          <div class="window-contain">
            <el-table
                :data="tableData"
                style="min-width: 200px; max-width: 500px"

            >
              <el-table-column
                  prop="date"
                  label="日期"
              >
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="姓名"
              >
              </el-table-column>
              <el-table-column
                  prop="address"
                  label="事件">
              </el-table-column>
              <el-table-column
                  prop="object"
                  label="对象">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="three">
        <div class="window">
          <div class="window-header">
            <span class="name">风险预警：距离交期还有7天订单</span>
          </div>
          <div class="window-contain">
            <el-table
                :data="tableData01"
                style="min-width: 200px; max-width: 500px"

            >
              <el-table-column
                  prop="date"
                  label="交期"
              >
              </el-table-column>
              <el-table-column
                  prop="progress"
                  label="当前进度"
              >
              </el-table-column>

              <el-table-column
                  prop="object"
                  label="PO号">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="window">
          <div class="window-header">
            <span class="name">超期订单</span>
          </div>
          <div class="window-contain">
            <el-table
                height="300px"
                :data="tableData02"
                style="min-width: 200px; max-width: 500px"

            >
              <el-table-column
                  prop="date"
                  label="交期"
              >
              </el-table-column>
              <el-table-column
                  prop="progress"
                  label="当前进度"
              >
              </el-table-column>

              <el-table-column
                  prop="object"
                  label="PO号">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="window">
          <div class="window-header">
            <span class="name">上月出货/库存/欠数情况</span>
          </div>
          <div class="window-contain">
            <SevenProductScrapRework></SevenProductScrapRework>
          </div>
        </div>
      </div>
      <div class="three right-three" >
        <div class="window">
          <div class="window-header"></div>
          <div class="window-contain">
            <ScrapReworkBarChart></ScrapReworkBarChart>
          </div>
        </div>
        <div class="window">
          <div class="window-header">
            <span class="name">月度统计</span>
          </div>
          <div class="window-contain">
            <BarChart></BarChart>
          </div>
        </div>
        <div class="window">
          <div class="window-header">
            <span class="name">年度统计</span>
          </div>
          <div class="window-contain">
            <YearBarChart></YearBarChart>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BarChart from "@/components/BarChart.vue";
import YearBarChart from "@/components/YearBarChart.vue";
import ScrapReworkBarChart from "@/components/ScrapReworkBarChart.vue";
import SevenProductScrapRework from "@/components/SevenProductScrapRework.vue";

export default {
  name: 'SubPage1',
  data() {
    return {
      tableData: [{
        date: '2024-06-30',
        name: '王小虎',
        address: '完成装配',
        object:'001'
      }, {
        date: '2024-06-20',
        name: '王小虎',
        address: '完成喷油',
        object:'001'
      }, {
        date: '2024-05-30',
        name: '王小虎',
        address: '完成贴花',
        object:'001'
      }, {
        date: '2024-05-03',
        name: '王小虎',
        address: '完成喷油',
        object:'001'
      }],
      tableData01: [{
        date: '2024-07-01',
        progress: '90%',
        object:'PO-02817D'
      }, {
        date: '2024-07-01',
        progress: '80%',
        object:'PO-02818D'
      }, {
        date: '2024-07-01',
        progress: '88%',
        object:'PO-02819D'
      }, {
        date: '2024-07-01',
        progress: '77%',
        object:'PO-02811D'
      }],
      tableData02: [{
        date: '2024-05-01',
        progress: '90%',
        object:'PO-02817D'
      }, {
        date: '2024-05-01',
        progress: '80%',
        object:'PO-02818D'
      }, {
        date: '2024-05-01',
        progress: '88%',
        object:'PO-02819D'
      }, {
        date: '2024-05-01',
        progress: '77%',
        object: 'PO-02811D'
      },
        {
          date: '2024-05-01',
          progress: '77%',
          object:'PO-02811D'
        },
        {
          date: '2024-05-01',
          progress: '77%',
          object:'PO-02811D'
        },
        {
          date: '2024-05-01',
          progress: '77%',
          object:'PO-02811D'
        },
        {
          date: '2024-05-01',
          progress: '77%',
          object:'PO-02811D'
        },]
    }
  },
  components:{

    BarChart,
    YearBarChart,
    ScrapReworkBarChart,
    SevenProductScrapRework,

  }

}
</script>

<style scoped>

.right-three {
  margin-right: 9px;
}

.common-header{
  display: flex;
  width: 100%;
  height: 100%; /* 设定一个高度以便看到效果 */
  padding-top: 13px;
}

.common-header .three{
  flex: 1;
  background: white;
  height: 1400px;

  margin-left: 9px;
  display: flex;
  flex-direction: column;
}

.common-header .three .window{
  border: 1px solid #b9c7d3;
  width: 100%;
  margin-bottom: 13px;
}

.common-header .three .window .window-header{
  width: 100%;
  height: 24px;
  background: linear-gradient(to bottom, #D4E6FC, #ffffff);
  color: #224499;

  padding-left: 5px;
  box-sizing: border-box;
}

.common-header .three .window .window-header .name{
  font-size: 12px;
  color: black;
  font-weight: bold;
}

.common-header .three .window .window-contain{
  width: 100%;
  height: 400px;
  background:#ffffff;
  display: flex;
  justify-content: center; /* 水平居中 */
}






</style>
